var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__space"
  }, [_c('component-title', [_c('h3', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("컴포넌트 선택")])]), _vm.isLoading ? _c('Spinner', {
    attrs: {
      "propsPartial": true
    }
  }) : _vm._e(), _c('div', {
    staticClass: "DAV-blockClass"
  }, [_c('div', {
    staticClass: "DAV-blockClass__dropdown"
  }, [_c('div', {
    staticClass: "DAV-dropdown"
  }, [_c('button', {
    staticClass: "DAV-dropdown__button",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.toggleCategory.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.categoryDefault.desc) + " ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.categoryVisible,
      expression: "categoryVisible"
    }],
    staticClass: "DAV-dropdown__list"
  }, [_c('div', {
    staticClass: "DAV-dropdown__selected"
  }, [_vm._v(" " + _vm._s(_vm.categoryDefault.desc) + " ")]), _c('ul', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside:toggleCategory",
      arg: "toggleCategory"
    }]
  }, _vm._l(_vm.category, function (category, index) {
    return _c('li', {
      key: index
    }, [_c('button', {
      class: [{
        'select-active': category.desc === _vm.categoryDefault.desc
      }, category.css],
      on: {
        "click": function click($event) {
          return _vm.setCategory(category.type, category.desc);
        }
      }
    }, [_vm._v(" " + _vm._s(category.desc) + " ")])]);
  }), 0)])])]), !Object.keys(_vm.componentListData).length && !_vm.isLoading ? _c('div', {
    staticClass: "errorLayout errorLayout__search"
  }, [_c('p', [_vm._v("일치하는 검색 결과가 없습니다.")])]) : _c('div', {
    staticClass: "DAV-blockClass__list"
  }, [_c('masonry', {
    attrs: {
      "cols": {
        default: 2,
        1023: 3,
        512: 2
      },
      "gutter": 16
    }
  }, _vm._l(_vm.componentListData, function (component, index) {
    var _component$attachFile;

    return _c('div', {
      key: index,
      staticClass: "DAV-blockClass__item"
    }, [_c('div', {
      staticClass: "DAV-blockClass__thumb"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          return _vm.componentPreview(component.id, $event);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": (_component$attachFile = component.attachFile) === null || _component$attachFile === void 0 ? void 0 : _component$attachFile.url,
        "alt": component.name
      },
      on: {
        "error": function error($event) {
          return _vm.errorImage(component.firstCategory, $event);
        },
        "load": _vm.loadImage
      }
    })]), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.getUserDevice === 'desktop',
        expression: "getUserDevice === 'desktop'"
      }],
      staticClass: "DAV-blockClass__over"
    }, [_c('button', {
      on: {
        "click": function click($event) {
          return _vm.componentPreview(component.id);
        }
      }
    }, [_vm._v(" 미리보기 ")])]), _c('div', {
      staticClass: "DAV-blockClass__desc"
    }, [_c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.filterDate(component.createdAt, component.firstCategory) && _vm.categoryDefault.type !== null,
        expression: "\n                  filterDate(component.createdAt, component.firstCategory) &&\n                  categoryDefault.type !== null\n                "
      }],
      staticClass: "DAV-blockClass__desc--new"
    }, [_vm._v(" NEW ")]), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: component.firstCategory === 'design' && _vm.categoryDefault.type !== 'design',
        expression: "\n                  component.firstCategory === 'design' &&\n                  categoryDefault.type !== 'design'\n                "
      }],
      staticClass: "DAV-blockClass__desc--design"
    }, [_vm._v(" 디자인 ")]), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: component.firstCategory === 'product' && _vm.categoryDefault.type !== 'product',
        expression: "\n                  component.firstCategory === 'product' &&\n                  categoryDefault.type !== 'product'\n                "
      }],
      staticClass: "DAV-blockClass__desc--product"
    }, [_vm._v(" 상품 ")]), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: component.firstCategory === 'solution' && _vm.categoryDefault.type !== 'solution',
        expression: "\n                  component.firstCategory === 'solution' &&\n                  categoryDefault.type !== 'solution'\n                "
      }],
      staticClass: "DAV-blockClass__desc--solution"
    }, [_vm._v(" " + _vm._s(_vm.solutionName) + " ")]), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: component.secondCategory === 'dynamic' && _vm.subCategoryDefault.type !== 'dynamic',
        expression: "\n                  component.secondCategory === 'dynamic' &&\n                  subCategoryDefault.type !== 'dynamic'\n                "
      }],
      staticClass: "DAV-blockClass__desc--dynamic"
    }, [_vm._v(" 다이나믹 ")])])]), _c('button', {
      on: {
        "click": function click($event) {
          return _vm.componentAdd(component.id, component.usableVersionId);
        }
      }
    }, [_c('span', [_vm._v("컴포넌트 추가 "), _c('i', {
      staticClass: "xi-arrow-bottom"
    })])])]);
  }), 0), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isListFetching,
      expression: "isListFetching"
    }],
    staticClass: "frmList__spinner"
  }, [_c('i', {
    staticClass: "xi-spinner-1 xi-spin"
  })])], 1)]), _c('GoTop')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }