<template>
  <div class="DAV-workshop__space">
    <component-title>
      <h3 slot="title">컴포넌트 선택</h3>
    </component-title>
    <Spinner v-if="isLoading" :propsPartial="true"></Spinner>
    <div class="DAV-blockClass">
      <div class="DAV-blockClass__dropdown">
        <div class="DAV-dropdown">
          <button class="DAV-dropdown__button" @click.stop="toggleCategory">
            {{ categoryDefault.desc }}
          </button>
          <div class="DAV-dropdown__list" v-show="categoryVisible">
            <div class="DAV-dropdown__selected">
              {{ categoryDefault.desc }}
            </div>
            <ul v-click-outside:toggleCategory>
              <li v-for="(category, index) in category" :key="index">
                <button
                  @click="setCategory(category.type, category.desc)"
                  :class="[
                    {'select-active': category.desc === categoryDefault.desc},
                    category.css
                  ]"
                >
                  {{ category.desc }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <div class="DAV-blockClass__filter" v-show="subCategoryShow === true">
        <div class="DAV-filter">
          <button class="DAV-filter__button" @click.stop="toggleSubCategory">
            <span>{{ subCategoryDefault.desc }}</span>
          </button>
          <ul
            class="DAV-filter__list"
            v-show="subCategoryVisible"
            v-click-outside:toggleSubCategory
          >
            <li v-for="(subCategory, index) in subCategory" :key="index">
              <button
                @click="setSubCategory(subCategory.type, subCategory.desc)"
              >
                {{ subCategory.desc }}
              </button>
            </li>
          </ul>
        </div>
      </div> -->
      <div
        class="errorLayout errorLayout__search"
        v-if="!Object.keys(componentListData).length && !isLoading"
      >
        <p>일치하는 검색 결과가 없습니다.</p>
      </div>
      <div class="DAV-blockClass__list" v-else>
        <masonry :cols="{default: 2, 1023: 3, 512: 2}" :gutter="16">
          <div
            class="DAV-blockClass__item"
            v-for="(component, index) in componentListData"
            :key="index"
          >
            <div class="DAV-blockClass__thumb">
              <a href="#" @click="componentPreview(component.id, $event)">
                <img
                  :src="component.attachFile?.url"
                  :alt="component.name"
                  @error="errorImage(component.firstCategory, $event)"
                  @load="loadImage"
                />
              </a>
              <div
                class="DAV-blockClass__over"
                v-show="getUserDevice === 'desktop'"
              >
                <button @click="componentPreview(component.id)">
                  미리보기
                </button>
              </div>
              <div class="DAV-blockClass__desc">
                <span
                  class="DAV-blockClass__desc--new"
                  v-show="
                    filterDate(component.createdAt, component.firstCategory) &&
                    categoryDefault.type !== null
                  "
                >
                  NEW
                </span>
                <span
                  class="DAV-blockClass__desc--design"
                  v-show="
                    component.firstCategory === 'design' &&
                    categoryDefault.type !== 'design'
                  "
                >
                  디자인
                </span>
                <span
                  class="DAV-blockClass__desc--product"
                  v-show="
                    component.firstCategory === 'product' &&
                    categoryDefault.type !== 'product'
                  "
                >
                  상품
                </span>
                <span
                  class="DAV-blockClass__desc--solution"
                  v-show="
                    component.firstCategory === 'solution' &&
                    categoryDefault.type !== 'solution'
                  "
                >
                  {{ solutionName }}
                </span>
                <span
                  class="DAV-blockClass__desc--dynamic"
                  v-show="
                    component.secondCategory === 'dynamic' &&
                    subCategoryDefault.type !== 'dynamic'
                  "
                >
                  다이나믹
                </span>
              </div>
            </div>
            <button
              @click="componentAdd(component.id, component.usableVersionId)"
            >
              <span>컴포넌트 추가 <i class="xi-arrow-bottom"></i></span>
            </button>
          </div>
        </masonry>
        <div class="frmList__spinner" v-show="isListFetching">
          <i class="xi-spinner-1 xi-spin"></i>
        </div>
      </div>
    </div>
    <GoTop />
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");
const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleEditor = createNamespacedHelpers("ModuleEditor");
const ModuleComponent = createNamespacedHelpers("ModuleComponent");

const PerPage = 20;
const PerBlock = 5;

export default {
  data() {
    return {
      page: 1,
      totalPage: 1,
      isLoading: false,
      isListFetching: false,
      categoryVisible: false,
      categoryDefault: {type: null, desc: "신규 컴포넌트"},
      category: [
        {type: null, desc: "신규 컴포넌트", css: "ga_e_9"},
        {type: "theme_component", desc: "테마 기본 컴포넌트", css: "ga_e_10"},
        {type: "design", desc: "디자인", css: "ga_e_11"},
        {type: "product", desc: "상품", css: "ga_e_12"},
        // {type: "solution", desc: "{$솔루션명} 기능"},
        {type: "html", desc: "HTML", css: "ga_e_13"}
      ],
      subCategoryShow: false,
      subCategoryVisible: false,
      subCategoryDefault: {type: null, desc: "전체"},
      subCategory: [
        {type: null, desc: "전체"},
        {type: "basic", desc: "베이직"},
        {type: "dynamic", desc: "다이나믹"}
      ],
      currentCategory: null,
      componentListData: [],
      componentListSize: 0,
      solutionName: null
    };
  },
  watch: {
    // 컴포넌트 목록
    getComponentListData(data) {
      this.componentListSize = data.list.length;
      this.componentListData.push(...data.list);

      this.totalPage = data.header.totalPage;

      if (!this.componentListSize) {
        this.isLoading = false;
        this.isListFetching = false;
      }
    }
  },
  computed: {
    ...ModuleAuth.mapGetters(["getLoggedData"]),
    ...ModuleEditor.mapGetters([
      "getSolutionListData",
      "getUserDevice",
      "getThemeId"
    ]),
    ...ModuleTheme.mapGetters(["getThemeLoadData"]),
    ...ModuleComponent.mapGetters(["getComponentListData"])
  },
  methods: {
    // image error
    errorImage(category, e) {
      e.target.src = require(`@/assets/images/common/img_${category}.svg`);
    },
    // image load
    loadImage() {
      this.componentListSize--;
      if (!this.componentListSize) {
        this.isLoading = false;
        this.isListFetching = false;
      }
    },
    // NEW ICON 노출 유무
    filterDate(createdAt, type) {
      let dtcount = createdAt.indexOf("T");
      let componentDate = createdAt.slice(0, dtcount);

      let date = new Date();
      date.setMonth(date.getMonth() - 3);

      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let currentDate = `${year}-${month}-${day}`;

      return (
        componentDate > currentDate &&
        (type === "design" || type === "product" || type === "solution")
      );
    },
    // 컴포넌트 선택
    componentAdd(componentId, componentVersionId) {
      this.setPanel("ComponentAdd");
      this.setComponentVersionId(componentVersionId);
      this.setComponentId(componentId);
    },
    // 컴포넌트 미리보기 선택
    componentPreview(componentId, e) {
      if (e) e.preventDefault();
      this.toggleCategory(false);
      this.setPopup({page: "ComponentPreview"});
      this.setComponentId(componentId);
    },
    // 카테고리 show/hide
    toggleCategory(value) {
      this.categoryVisible = !value ? value : !this.categoryVisible;
    },
    // 카테고리 선택
    setCategory(type, desc) {
      this.toggleCategory(false);

      if (this.categoryDefault.type === type) return;

      this.currentCategory = type;
      this.categoryDefault.type = type;
      this.categoryDefault.desc = desc;

      if (type === "design" || type === "product") {
        this.subCategoryShow = true;
      } else {
        this.subCategoryShow = false;
        this.subCategoryDefault = {type: null, desc: "전체"};
      }

      this.page = 1;
      this.componentListData = [];
      this.isLoading = true;
      if (type === "theme_component") {
        this.themeComponentList();
        return;
      }
      this.componentList();
    },
    // 하위 카테고리 show/hide
    toggleSubCategory(value) {
      this.subCategoryVisible = !value ? value : !this.subCategoryVisible;
    },
    // 하위 카테고리 선택
    setSubCategory(type, desc) {
      this.toggleSubCategory(false);

      if (this.subCategoryDefault.type === type) return;

      this.subCategoryDefault.type = type;
      this.subCategoryDefault.desc = desc;

      this.page = 1;
      this.componentListData = [];
      this.isLoading = true;
      this.componentList();
    },
    // scroll event
    onScroll(e) {
      const {scrollHeight, scrollTop, clientHeight} = e.target;

      let atBottom = scrollHeight === scrollTop + clientHeight;
      if (atBottom && !this.isListFetching && this.page < this.totalPage) {
        this.page++;
        this.isListFetching = true;
        this.componentList();
      }
    },
    // 컴포넌트 목록
    componentList() {
      let payload = {};
      payload.solutionId = this.getThemeLoadData.theme.solutionId;
      payload.sortBy = "-id";
      payload.page = this.page;
      payload.perPage = PerPage;
      payload.perBlock = PerBlock;
      payload.display = 1;
      payload.status = "registered";

      if (!isNaN(this.currentCategory)) {
        payload.userPartnerId = this.currentCategory;
      } else {
        payload.firstCategory = this.categoryDefault.type;
        payload.secondCategory = this.subCategoryDefault.type;
      }

      if (!this.categoryDefault.type) {
        const newDate = new Date();
        const date = newDate.setDate(newDate.getDate() - 90);
        payload.startedAt = new Date(date).toISOString();
        payload.firstCategory = "design, product";
      }

      this.actComponentList(payload).then(() => {
        this.isLoading = false;
        if (this.categoryDefault.type === "html") {
          this.componentAdd(
            this.componentListData[0].id,
            this.componentListData[0].usableVersionId
          );
        }
      });
    },
    // 테마 컴포넌트 목록
    themeComponentList() {
      let payload = {};
      payload.themeId = this.getThemeLoadData.theme.themeId;
      payload.status = "registered";
      payload.display = 1;

      this.actThemeComponentList(payload).then(response => {
        this.isLoading = false;
        this.componentListSize = response.data.length;
        this.componentListData.push(...response.data);
        this.totalPage = 1;
        this.isListFetching = false;
      });
    },
    // 테마 통합 불러오기
    getThemeLoad() {
      let solutionId = this.getThemeLoadData.theme.solutionId;

      for (let solution of this.getSolutionListData) {
        if (solutionId === solution.id) {
          this.solutionName = solution.name;
        }
      }

      for (let item of this.category) {
        if (item.type === "solution") {
          item.desc = item.desc.replace("{$솔루션명}", this.solutionName);
        }
      }
    },

    ...ModuleComponent.mapActions([
      "actComponentList",
      "actThemeComponentList"
    ]),
    ...ModuleEditor.mapMutations([
      "setPopup",
      "setPanel",
      "setComponentVersionId",
      "setComponentId"
    ])
  },
  created() {
    if (this.$route.query.mode === "partner") {
      this.currentCategory = this.getLoggedData.id;
      this.categoryDefault["type"] = this.getLoggedData.id;
      this.categoryDefault["desc"] = "나의 컴포넌트";
      this.category[0].type = this.getLoggedData.id;
      this.category[0].desc = "나의 컴포넌트";
      this.category.splice(1, 1);
      this.category.splice(3, 1);
    }
  },
  mounted() {
    // scroll event 추가
    if (document.querySelector(".DAV-blockClass")) {
      document
        .querySelector(".DAV-blockClass")
        .addEventListener("scroll", this.onScroll);
    }

    // 컴포넌트 목록
    this.isLoading = true;
    this.componentList();

    // 테마 통합 불러오기
    this.getThemeLoad();
  },
  beforeDestroy() {
    // scroll event 삭제
    if (document.querySelector(".DAV-blockClass")) {
      document
        .querySelector(".DAV-blockClass")
        .removeEventListener("scroll", this.onScroll);
    }
  },
  components: {
    Spinner: () => import("@/components/basis/spinner.vue"),
    GoTop: () => import("@/components/modules/GoTop.vue"),
    ComponentTitle: () => import("@/components/editor/ComponentTitle.vue")
  }
};
</script>

<style lang="scss" scoped>
.DAV-blockClass {
  &__dropdown {
    z-index: 3;
    position: relative;
    padding: 16px;
    background-color: #54c7a2;
  }
  &__filter {
    z-index: 2;
    position: relative;
    padding: 16px 16px 0;
  }
  &__list {
    z-index: 1;
    position: relative;
    padding: 16px;
  }
  &__item {
    margin-bottom: 24px;

    > button {
      position: relative;
      width: 100%;
      height: 32px;
      margin-top: 8px;
      background-color: #f7f9fc;
      border: 1px solid #b7b7b7;
      box-sizing: border-box;
      color: #8d8d8d;
      font-size: 14px;
    }
  }
  &__thumb {
    position: relative;

    img {
      width: 100%;
      vertical-align: middle;
    }
    &:hover {
      .DAV-blockClass__over {
        display: flex;
      }
    }
  }
  &__over {
    display: none;
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);

    > button {
      width: 120px;
      height: 40px;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }
  }
  &__desc {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;

    span {
      display: flex;
      align-items: center;
      height: 20px;
      padding: 0 4px;
      font-size: 12px;
      font-weight: 500;
    }
    &--design {
      background-color: #ff7575;
      color: #ffffff;
    }
    &--product {
      background-color: #ffe175;
      color: #734e2c;
    }
    &--solution {
      background-color: #1d57dc;
      color: #ffffff;
    }
    &--new {
      background-color: #000000;
      color: #ffffff;
    }
    &--dynamic {
      background-color: #985fe8;
      color: #ffffff;
    }
  }
}
.DAV-filter {
  position: relative;
  display: flex;

  &__button {
    height: 20px;
    padding: 0 26px;
    border: 1px solid #b7b7b7;
    box-sizing: border-box;
    border-radius: 8px;
    color: #8d8d8d;
    font-size: 12px;
    font-weight: 500;

    span {
      position: relative;
      display: block;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        width: 12px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
      &::before {
        left: -16px;
        background-image: url($path + "bul_filter.svg");
      }
      &::after {
        right: -16px;
        background-image: url($path + "bul_arr_bottom_gray.svg");
      }
    }
  }
  &__list {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(50% - 8px);
    padding: 16px 0;
    border: 1px solid #d8dae5;
    @include box;

    button {
      width: 100%;
      height: 40px;
      padding: 0 16px;
      border-radius: 8px;
      text-align: left;
      outline: none;
    }
  }
}
.errorLayout {
  top: 80px;
}
.frmList__spinner {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: #309574;
    font-size: 30px;
  }
}
.DAV-dropdown {
  &__button {
    border: none;
  }
  &__list {
    ul {
      max-height: 288px;
    }
  }
}
/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-blockClass {
    top: 56px;
  }
}
</style>
